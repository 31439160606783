<template>
  <div class="activityReservation">
    <div class="info_box">
      <h3>{{ data.activityName || data.exhibitName }}</h3>
      <p v-if="data.isRegister">报名日期：{{ data.registrationTime }}</p>
      <p>{{ typeText }}时间：{{ data.activityDate }} {{ data.activityTime }}</p>
      <p>
        {{ typeText }}地点：{{ data.activityAddress || data.exhibitAddress }}
      </p>
      <p>
        {{ typeText }}限制：{{ data.numberOnline
        }}{{ data.needParent === 1 ? "组" : "人" }}
        <span class="ageLimit"
          >年龄限制：{{ data.minAge + "-" + data.maxAge }}岁</span
        >
      </p>
      <p v-if="data.isRegister">
        报名限制：{{ data.numberRegister
        }}{{ data.needParent === 1 ? "组" : "人" }}，<span
          style="color: #0b6cf9"
          >{{ orderNum || 0 }}</span
        >{{ data.needParent === 1 ? "组" : "人" }}已报名
        <span class="lottery" v-if="data.needChoose">摇号</span>
      </p>
      <p v-if="data.needPay">
        {{ typeText }}费用：<span style="color: #fd4d4f"
          >￥{{ data.price }}元</span
        >
      </p>
    </div>
    <div class="tips">
      <p>
        <van-icon name="warning" />温馨提示：
        <template v-if="data.maxAllowPreUser > 1">
          参与者中的家长可以和紧急联系人为同一人，必须年满18周岁以上。
          <span class="red_color" v-if="data.needParent === 1"
            >家长必须陪同参入馆，一起参与活动</span
          >
          <span class="red_color" v-else
            >家长必须陪同参入馆，家长不能参与活动</span
          >
        </template>
        <template v-else>
          紧急联系人必须年满18周岁以上。针对成人可单独参与的活动，联系人可以和参与者为同一人。
        </template>
      </p>
    </div>
    <div class="box">
      <div class="title title_box">
        <span>紧急联系人<span class="red">（不提供入馆码）</span></span>
        <div class="add_btn" @click="addPersonnel(1, 'list', 'list2')">
          添加/更换
        </div>
      </div>
      <div class="form_box" v-if="list.length">
        <div v-for="(item, index) in list" :key="index" class="item">
          <img
            src="@/assets/images/delete_p.png"
            alt=""
            @click="del(item, 'list')"
          />
          <div class="right">
            <div class="info">
              <p class="name">
                {{ item.name }}
              </p>
              <p>证件号码：{{ item.documentNumber }}</p>
              <p>手机号码：{{ item.phone }}</p>
              <van-form label-width="65" colon ref="vanForm">
                <van-field
                  v-if="data.field.includes('年龄') && item.documentType !== 1"
                  v-model="item.age"
                  label="年龄"
                  placeholder="请输入年龄"
                  :rules="[{ required: true, message: '请输入年龄' }]"
                />
                <van-field
                  name="sex"
                  v-if="data.field.includes('性别') && item.documentType !== 1"
                  label="性别"
                  :rules="[{ required: true, message: '请选择性别' }]"
                >
                  <template #input>
                    <van-radio-group v-model="item.sex" direction="horizontal">
                      <van-radio :name="0">男</van-radio>
                      <van-radio :name="1">女</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
              </van-form>
            </div>
          </div>
        </div>
      </div>
      <p v-else class="please_select">请选择1位家长</p>
    </div>
    <div class="box">
      <div class="title title_box">
        <span>参与者信息</span>
        <div
          class="add_btn"
          @click="addPersonnel(data.maxAllowPreUser, 'list2', 'list')"
        >
          添加/更换
        </div>
      </div>
      <p class="prompt" v-if="data.maxAllowPreUser > 1">
        （提供{{ data.maxAllowPreUser }}人入馆码，家长须陪同入馆，<template
          v-if="data.needParent === 1"
          >一起参与活动</template
        ><template v-else>家长不能参与活动</template>）
      </p>
      <p class="prompt" v-else>（提供1入馆码）</p>
      <div class="form_box" v-if="list2.length">
        <div v-for="(item, index) in list2" :key="index" class="item">
          <img
            class="del_img"
            src="@/assets/images/delete_p.png"
            alt=""
            @click="del(item, 'list2')"
          />
          <div class="right">
            <div class="info">
              <p class="name">{{ item.name }}</p>
              <p>证件号码：{{ item.documentNumber }}</p>
              <p>手机号码：{{ item.phone }}</p>
              <van-form label-width="65" colon ref="vanForm">
                <van-field
                  v-if="data.field.includes('年龄') && item.documentType !== 1"
                  v-model="item.age"
                  label="年龄"
                  placeholder="请输入年龄"
                  :rules="[{ required: true, message: '请输入年龄' }]"
                />
                <van-field
                  name="sex"
                  v-if="data.field.includes('性别') && item.documentType !== 1"
                  label="性别"
                  :rules="[{ required: true, message: '请选择性别' }]"
                >
                  <template #input>
                    <van-radio-group v-model="item.sex" direction="horizontal">
                      <van-radio :name="0">男</van-radio>
                      <van-radio :name="1">女</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
              </van-form>
            </div>
          </div>
        </div>
      </div>
      <p v-if="!list2.length" class="please_select">
        <template v-if="data.needParent === 1">
          {{ data.maxAllowPreUser > 1 ? "请选择2人，一大一小" : "请选择1人" }}
        </template>
        <template v-else>
          {{ "最多选择" + data.maxAllowPreUser + "人" }}
        </template>
      </p>
    </div>
    <div
      class="box"
      v-if="data.field && data.field.length && data.field.includes('年级段')"
    >
      <div class="title title_box">
        <span>年级段</span>
      </div>
      <van-form label-width="65" colon ref="vanForm2">
        <van-field
          v-model="ageGroup"
          label="年级段"
          placeholder="请输入年级段"
          :rules="[{ required: true, message: '请输入年级段' }]"
        />
      </van-form>
    </div>
    <div class="btn_box">
      <van-button type="primary" class="btn_sty" @click="nextStep">
        <p>
          立即报名 （{{
            data.maxAllowPreUser > 1
              ? "参与者信息须添加一大一小"
              : "参与者信息只能添加1人"
          }}）
        </p>
        <p v-if="list2.length">
          已选{{ list2.length }}人<span v-if="data.needPay"
            >费用：<span>￥{{ data.price }}</span></span
          >
        </p>
      </van-button>
      <div
        style="position: absolute; width: 100%; height: 100%; top: 0px"
        v-if="isSubscribe && templateId.length"
      >
        <OpenSubscribe
          :templateId="templateId"
          @open-subscribe-success="openSubscribeSuccess"
          @open-subscribe-error="openSubscribeError"
        >
        </OpenSubscribe>
      </div>
    </div>

    <van-dialog
      v-model:show="show"
      title="温馨提示"
      show-cancel-button
      confirmButtonText="确认报名"
      confirmButtonColor="#458aef"
      :confirmButtonDisabled="!reading"
      @confirm="confirm"
      @close="close"
      class="confirm_tips"
    >
      <div class="content" v-html="data.tips"></div>
      <van-checkbox v-model="reading" shape="square" icon-size="16px"
        >我已阅读并遵守以上规定</van-checkbox
      >
    </van-dialog>
    <van-dialog
      v-model:show="show2"
      class="appointment"
      confirm-button-text="知道了"
      confirm-button-color="#fff"
      @confirm="$router.replace('/')"
    >
      <template #title>
        <div class="dialog_tit" v-if="!data.needChoose && !data.needPay">
          <van-icon name="checked" /><span>报名成功！</span>
        </div>
        <div class="dialog_tit" v-else>
          <span>温馨提示</span>
        </div>
      </template>
      <div class="tips_content">
        <p v-if="!data.needChoose && !data.needPay">
          1、如您不能及时前往，请在<span class="red_color"
            >活动开始前{{
              config.retreatTimeHour ? config.retreatTimeHour + "小时" : ""
            }}{{ config.retreatTimeMin }}分钟</span
          >提前取消预约（如<span class="red_color"
            >{{ config.maxDefaultsNum }}次</span
          >不及时取消，账号将在<span class="red_color"
            >{{ config.resetDefaultsDay }}天</span
          >内无法进行预约）。
        </p>
        <p v-else>
          1、您的报名申请已提交成功，请耐心等待摇号，摇号结果将以短信通知联系人！
        </p>
        <p>
          2、{{
            !data.needChoose && !data.needPay ? "" : "如果中签将"
          }}为您提供{{ data.maxAllowPreUser }}人入馆码，此活动<span
            v-if="data.maxAllowPreUser === 1"
            class="red_color"
            >仅限{{ data.maxAllowPreUser }}人参与</span
          ><span v-if="data.maxAllowPreUser > 1" class="red_color"
            >需要家长陪同入馆<span v-if="data.needParent === 1"
              >一起参与活动</span
            ><span v-else>，但是家长不参与活动</span></span
          >。
        </p>
        <p>3、在“我的—活动报名记录”中查看订单信息。</p>
      </div>
    </van-dialog>
    <MyNotice ref="myNotice" />
    <van-popup
      class="commonly_popup"
      v-model:show="commonlyShow"
      position="right"
    >
      <CommonlyUsed
        :max="max"
        :ids="ids"
        :disableds="disableds"
        @personnel="personnel"
      />
    </van-popup>
  </div>
</template>
<script>
import MyNotice from "@/components/MyNotice.vue";
import OpenSubscribe from "@/components/OpenSubscribe.vue";
import CommonlyUsed from "./CommonlyUsed.vue";
import {
  activity_find,
  activity_book,
  activity_ageFit,
  exhibit_find,
  exhibit_book,
  exhibit_ageFit,
} from "@/api/ActivityList";
import { common_config_get } from "@/api/api";
import { activity_order_pay, exhibit_order_pay } from "@/api/MyTicket";
import { contactList } from "@/api/CommonlyUsed";
export default {
  name: "ActivityReservation",
  data() {
    return {
      type: "",
      id: null,
      typeText: "",
      data: {},
      show: false,
      show2: false,
      findApi: null,
      bookApi: null,
      ageFitApi: null,
      orderPayApi: null,
      list: [],
      list2: [],
      contactList: [],
      max: 0,
      ids: [],
      disableds: [],
      commonlyShow: false,
      commonlyActive: "",
      templateId: [],
      isSubscribe: true,
      reading: false,
      config: {},
      ageGroup: "",
      orderNum: null,
    };
  },
  props: {
    clickRightNum: {
      type: Number,
      default: 0,
    },
  },
  computed: {},
  components: { MyNotice, CommonlyUsed, OpenSubscribe },
  created() {
    let { id, type } = this.$route.query;
    this.type = type;
    this.id = id;
    if (this.type === "活动详情") {
      this.typeText = "活动";
      this.findApi = activity_find;
      this.bookApi = activity_book;
      this.ageFitApi = activity_ageFit;
      this.orderPayApi = activity_order_pay;
      this.getConfig(4);
    } else if (this.type === "临展详情") {
      this.typeText = "临展";
      this.findApi = exhibit_find;
      this.bookApi = exhibit_book;
      this.ageFitApi = exhibit_ageFit;
      this.orderPayApi = exhibit_order_pay;
      this.getConfig(5);
    }
    this.getFind();
  },
  mounted() {},
  methods: {
    async getConfig(ticketType) {
      let { data } = await common_config_get({ ticketType });
      if (data.refundTimeLimit >= 0) {
        data.retreatTimeHour = parseInt(data.refundTimeLimit / 60);
        data.retreatTimeMin = data.refundTimeLimit - data.retreatTimeHour * 60;
      } else {
        data.retreatTimeHour = 0;
        data.retreatTimeMin = 0;
      }
      this.config = data;
    },
    async getFind() {
      let form = {};
      if (this.typeText === "活动") {
        form.activityId = this.id;
      } else {
        form.exhibitId = this.id;
      }
      let {
        data: { activity, exhibit, orderNum },
      } = await this.findApi({ ...form });
      let {
        registrationStartTime,
        registrationEndTime,
        activityStartTime,
        activityEndTime,
        exhibitStartTime,
        exhibitEndTime,
        field,
        needChoose,
      } = activity || exhibit;
      if (needChoose) {
        this.templateId = [
          "kbpEUv9hZNuza0jFhg2xqbJhver8mB1TgKGaFHzQohc",
          "OM0AP1EFMJ2-iuwq3-7fx3oOGnlk5wkHhW1Wl5fW0Tg",
        ];
      } else {
        this.templateId = [
          "YcVKt1q2iwIitXmyHdTqJwoiRLzD5-tfHm4daab_ggQ",
          "qgKUH1hBsvMWYhGPr1JTDOL2IVXUTSAZ3Uplvj1ykJ0",
        ];
      }
      this.orderNum = orderNum;
      this.data = { ...(activity || exhibit) };
      this.data.registrationTime =
        registrationStartTime + " 至 " + registrationEndTime;
      this.data.activityTime =
        (activityStartTime || exhibitStartTime) +
        " 至 " +
        (activityEndTime || exhibitEndTime);
      this.data.field = field ? field.split(",") : [];
    },
    del(item, active) {
      this[active] = this[active].filter((item2) => item2.id !== item.id);
    },
    async personnel(ids) {
      let { data } = await contactList();
      this.contactList = data;
      let arr = [];
      ids.map((item) => {
        arr = arr.concat(this.contactList.filter((item2) => item2.id === item));
      });
      this[this.commonlyActive] = arr;
      this.commonlyShow = false;
    },
    addPersonnel(num, active, active2) {
      let ids = [];
      // let ids2 = [];
      this.commonlyActive = active;
      this[active].map((item) => {
        ids.push(item.id);
      });
      // this[active2].map((item) => {
      //   ids2.push(item.id);
      // });
      // this.disableds = ids2;
      this.ids = ids;
      this.max = num;
      this.commonlyShow = true;
    },
    //确认提交
    async confirm() {
      let orderItems = [];
      this.list2.map((v) => {
        let { id, age, sex } = v;
        orderItems.push({
          contactId: id,
          age,
          sex,
          ageGroup: this.ageGroup,
        });
      });
      let form = {};
      if (this.typeText === "活动") {
        form = {
          activityId: this.id,
          contactId: this.list[0].id,
          age: this.list[0].age,
          sex: this.list[0].sex,
          activityOrderItems: orderItems,
        };
      } else {
        form.exhibitId = this.id;
        form = {
          exhibitId: this.id,
          contactId: this.list[0].id,
          age: this.list[0].age,
          sex: this.list[0].sex,
          exhibitOrderItems: orderItems,
        };
      }
      let {
        data: { activityOrderItems, exhibitOrderItems },
      } = await this.ageFitApi(form);
      if (this.typeText === "活动") {
        form.activityOrderItems.forEach((v) => {
          activityOrderItems.forEach((v2) => {
            if (v2.contactId === v.contactId) {
              v.isAdult = v2.isAdult;
            }
          });
        });
      } else {
        form.exhibitOrderItems.forEach((v) => {
          exhibitOrderItems.forEach((v2) => {
            if (v2.contactId === v.contactId) {
              v.isAdult = v2.isAdult;
            }
          });
        });
      }
      let {
        data: { orderNo, activityOrderId, exhibitOrderId },
      } = await this.bookApi(form);
      if (!this.data.needChoose && this.data.needPay) {
        let data = {
          id: activityOrderId || exhibitOrderId,
          orderNo,
        };
        if (this.typeText === "活动") {
          data.activityId = this.id;
        } else {
          data.exhibitId = this.id;
        }
        this.toPay(data);
      } else {
        this.show2 = true;
      }
    },
    //支付
    async toPay(data) {
      let {
        data: { payUrl },
      } = await this.orderPayApi(data);
      window.location.href = payUrl;
    },
    //下一步
    async nextStep() {
      if (!this.list.length) {
        this.$notify({
          type: "danger",
          message: "请添加1位联系人",
        });
      } else if (this.list2.length != this.data.maxAllowPreUser) {
        this.$notify({
          type: "danger",
          message: "请添加" + this.data.maxAllowPreUser + "位参与者信息",
        });
      }
      //  else if (
      //   this.data.needParent === 1 &&
      //   this.list2.length != this.data.maxAllowPreUser
      // ) {
      //   this.$notify({
      //     type: 'danger',
      //     message: '请添加' + this.data.maxAllowPreUser + '位人员信息',
      //   });
      // } else if (this.data.needParent === 0 && !this.list2.length) {
      //   this.$notify({
      //     type: 'danger',
      //     message: '请至少添加1位人员信息',
      //   });
      // }
      else {
        if (this.$refs.vanForm || this.$refs.vanForm2) {
          if (await this.validate()) {
            this.show = true;
          }
        } else {
          this.show = true;
        }
      }
    },
    validate() {
      let vm = this;
      let arr = [];
      if (this.$refs.vanForm) {
        let oneForm = new Promise((resolve, reject) => {
          let num = 0;
          for (let i = 0; i < this.$refs.vanForm.length; i++) {
            vm.$refs.vanForm[i]
              .validate()
              .then(() => {
                num++;
                if (num === this.$refs.vanForm.length) {
                  resolve(true);
                }
              })
              .catch((err) => {
                if (err && err.length > 0 && err[0].name) {
                  vm.$refs.vanForm[i].scrollToField(err[0].name, false);
                }
                reject();
              });
          }
        });
        arr.push(oneForm);
      }
      if (vm.$refs.vanForm2) {
        let twoForm = new Promise((resolve, reject) => {
          vm.$refs.vanForm2
            .validate()
            .then(() => {
              resolve();
            })
            .catch((err) => {
              if (err && err.length > 0 && err[0].name) {
                vm.$refs.vanForm2.scrollToField(err[0].name, false);
              }
              reject();
            });
        });
        arr.push(twoForm);
      }
      return new Promise((resolve) => {
        Promise.all(arr)
          .then(() => {
            resolve(true);
          })
          .catch(() => {});
      });
      // let num = 0;
      // return new Promise((resolve) => {
      //   for (let i = 0; i < this.$refs.vanForm.length; i++) {
      //     this.$refs.vanForm[i].validate().then(() => {
      //       num++;
      //       if (num === this.$refs.vanForm.length) {
      //         resolve(true);
      //       }
      //     });
      //   }
      // });
    },
    openSubscribeSuccess() {
      this.isSubscribe = false;
      this.nextStep();
    },
    openSubscribeError() {
      this.isSubscribe = false;
      this.nextStep();
    },
    close() {
      this.reading = false;
    },
  },
  watch: {
    //点击预约须知
    clickRightNum() {
      this.$refs.myNotice.init(this.typeText, false, this.data.notice);
    },
  },
};
</script>
<style lang="scss" scoped>
.activityReservation {
  height: 100%;
  background: #f5f6fa;
  min-height: calc(100vh - 92px);
  padding-bottom: 46px;
  .info_box {
    background-color: #fff;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 10px solid #ececec;
    h3 {
      font-size: 16px;
      color: #333;
      line-height: 30px;
    }
    p {
      font-size: 14px;
      color: #666;
      line-height: 25px;
    }
    .ageLimit {
      margin-left: 20px;
    }
    .lottery {
      margin-left: 15px;
      border: 1px solid #fd4d4f;
      padding: 2px 5px;
      color: #fd4d4f;
      font-size: 10px;
    }
  }
  .tips {
    background-color: #fefbf1;
    border: 1px solid #ffb500;
    border-radius: 3px;
    padding: 10px 14px;
    p {
      font-weight: 400;
      font-style: normal;
      font-size: 12px;
      color: #666666;
      line-height: 20px;
      font-size: 14px;
      color: #f9871e;
      i {
        margin-right: 5px;
      }
      .red_color {
        color: #fd4d4f;
      }
    }
  }
  .box {
    margin: 10px;
    background: #fff;
    border-radius: 5px;
    padding: 15px 20px;
    .title {
      padding-bottom: 10px;
      border-bottom: 1px solid #ececec;
      display: flex;
      align-items: center;
      > span {
        font-size: 14px;
        font-weight: 500;
        color: rgb(51, 51, 51);
        .red {
          color: #fd4d4f;
          font-size: 12px;
        }
      }
      .add_btn {
        font-size: 14px;
        font-weight: 400;
        color: #0b6cf9;
      }
    }
    .please_select {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      margin-top: 10px;
    }
    .title_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .form_box {
      background: #fdfdfd;
      border-radius: 5px;
      .item {
        border-bottom: 1px solid #dbdbdb;
        background-color: #fff;
        padding: 10px 0 10px 20px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          display: block;
        }
        .right {
          width: 100%;
          padding-left: 10px;
          margin-left: 5px;
          // display: flex;
          // align-items: center;
          // justify-content: space-between;
          .info {
            > p {
              font-size: 13px;
              line-height: 20px;
            }
            .name {
              font-size: 15px;
              line-height: 24px;
            }
          }
          ::v-deep .van-form {
            .van-cell {
              padding: 5px 0;
              .van-cell__title {
                margin-right: 0;
                > label {
                  display: block;
                  width: 100%;
                  font-size: 13px;
                  color: #000;
                }
              }
              .van-field__control {
                border: 1px solid #ccc;
                padding: 0 4px;
                font-size: 13px;
                .van-checkbox-group {
                  .van-checkbox + .van-checkbox {
                    margin-top: 5px;
                  }
                }
                .upload_box {
                  > div {
                    color: #256fec;
                  }
                  .van-uploader {
                    margin: 0;
                  }
                  .fileList {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  }
                }
              }
              .van-field__control--custom {
                border: none;
              }
            }
          }
        }
      }
    }
    .prompt {
      padding: 5px 0;
      color: #fd4d4f;
      font-size: 12px;
    }
  }
  .btn_box {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 38px;
  }
  ::v-deep .btn_sty {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    font-weight: 650;
    color: #fff;
    .van-button__content {
      padding: 10px 0;
    }
    .van-button__text {
      p {
        margin: 0;
        font-size: 14px;
        line-height: 16px;
        > span {
          > span {
            line-height: 16px;
            font-size: 16px;
          }
        }
      }
    }
  }
  ::v-deep .confirm_tips {
    .van-dialog__header {
      padding: 0;
      line-height: 50px;
      font-weight: bold;
    }
    .van-dialog__content {
      padding: 0 15px 10px;
      font-size: 16px;
      font-weight: 400;
      .content {
        line-height: 1.8;
        overflow-y: auto;
        max-height: 66vh;
      }
      .van-checkbox {
        margin-top: 10px;
        .van-checkbox__label {
          color: #1989fa;
        }
      }
    }
  }
  ::v-deep .appointment {
    .van-dialog__header {
      padding: 15px 0;
      font-size: 16px;
      color: #333333;
      font-weight: 650;
      .dialog_tit {
        display: flex;
        justify-content: center;
        align-items: center;
        > i {
          color: #4bd863;
          font-size: 22px;
          margin-right: 10px;
        }
      }
    }
    .van-dialog__content {
      padding: 0 20px 20px;
      .tips_content {
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: #333;
        line-height: 24px;
        .red_color {
          color: #fd4d4f;
        }
      }
    }
    .van-dialog__footer {
      .van-button {
        background-color: #3069f0;
      }
    }
  }
  ::v-deep .commonly_popup {
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    .commonlyUsed {
      height: 100vh;
      .van-checkbox-group {
        margin-bottom: 60px;
      }
    }
  }
}
</style>
